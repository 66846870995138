/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
// import "./layout.css"

const Layout = ({
  className,
  children,
  isHeaderTransparent,
  isPoweredPage,
  isLabsPage,
  isLandingPage,
  demoSchedule,
  footerLayout2,
  footerLayout3,
  footerLayout4,
  footerLayout5,
  footerUrlAlt,
  signUp,
  signUpUrlAlt,
  headerNoButtons,
  freeTrialAmount,
  isCreditRepairPage,
  footerCTAContent,
  signUpBtnConfig,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.insertAdjacentHTML(
        "afterbegin",
        '<noscript id="google_tag"><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N9TTN8F" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>'
      )
    }
    return () => {
      if (typeof window !== "undefined") {
        document.body.removeChild(document.getElementById("google_tag"))
      }
    }
  })

  function loadScript(url, callback) {
    if (typeof window !== "undefined") {
      var script = document.createElement("script")
      script.setAttribute("id", "billsby-checkout")
      script.setAttribute("data-billsby-company", "beach")

      script.type = "text/javascript"

      if (script.readyState) {
        script.onreadystatechange = function() {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null
            callback()
          }
        }
      } else {
        script.onload = function() {
          callback()
        }
      }

      script.src = url
      document.getElementsByTagName("head")[0].appendChild(script)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      loadScript("https://checkoutlib.billsby.com/checkout.min.js", function() {
        window.scanDomBillsby()
      })
    }
    return () => {
      if (typeof window !== "undefined") {
        document.head.removeChild(document.getElementById("billsby-checkout"))
      }
    }
  })

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        isHeaderTransparent={isHeaderTransparent}
        isLandingPage={isLandingPage}
        demoSchedule={demoSchedule}
        signUp={signUp}
        signUpUrlAlt={signUpUrlAlt}
        isPoweredPage={isPoweredPage}
        headerNoButtons={headerNoButtons}
        isLabsPage={isLabsPage}
        isCreditRepairPage={isCreditRepairPage}
        signUpBtnConfig={signUpBtnConfig}
      />
      <div>
        <main className={className}>{children}</main>
        {/* <a
          href="https://www.producthunt.com/posts/billsby?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-billsby"
          className="product-hunt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=207351&theme=dark&period=daily" />
        </a> */}
      </div>
      <Footer
        footerLayout2={footerLayout2}
        footerLayout3={footerLayout3}
        footerLayout4={footerLayout4}
        footerLayout5={footerLayout5}
        footerUrlAlt={footerUrlAlt}
        freeTrialAmount={freeTrialAmount}
        footerCTAContent={footerCTAContent}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
