const BASE_URL = 'https://billsby-mailgun.onrender.com';
// const BASE_URL = 'http://127.0.0.1:9000';

export const HomeURLS = {
  // thirtyDays: BASE_URL + '/api/customer-signups/statistics',
  // sixtyDays: BASE_URL + '/api/customer-signups/statistics/sixty',
  // ninetyDays: BASE_URL + '/api/customer-signups/statistics/ninety',
  // yearly: BASE_URL + '/api/customer-signups/statistics/yearly',

  // thirtyDaysASC: BASE_URL + '/api/customer-signups/statistics/asc',
  // sixtyDaysASC: BASE_URL + '/api/customer-signups/statistics/sixty/asc',
  // ninetyDaysASC: BASE_URL + '/api/customer-signups/statistics/ninety/asc',
  // yearlyASC: BASE_URL + '/api/customer-signups/statistics/yearly/asc',
  thirtyDays: BASE_URL + '/api/customer-signups/statistics',
  sixtyDays: BASE_URL + '/api/customer-signups/statistics/sixty',
  ninetyDays: BASE_URL + '/api/customer-signups/statistics/ninety',
  yearly: BASE_URL + '/api/customer-signups/statistics/yearly',

  // thirtyDaysASC: BASE_URL + '/api/customer-signups/statistics/asc',
  thirtyDaysASC: BASE_URL + '/api/customer-signups/statistics?order=asc&days=30',
  thirtyDaysDESC: BASE_URL + '/api/customer-signups/statistics?order=desc&days=30',
  sixtyDaysASC: BASE_URL + '/api/customer-signups/statistics/sixty/asc',
  ninetyDaysASC: BASE_URL + '/api/customer-signups/statistics/ninety/asc',
  // yearlyASC: BASE_URL + '/api/customer-signups/statistics/yearly/asc',
  yearlyASC: BASE_URL + '/api/customer-signups/statistics?order=asc&year=1',
};

export const marketingURLS = {
  statistics: BASE_URL + '/api/marketing/statistics',
};

export const MarketingConversionContent = {
  fetchAnalytics:
    'https://cors-anywhere.herokuapp.com/https://simpleanalytics.com/billsby.com.json',
};

export const AdBudget = {
  get: 'https://billsby-mailgun.onrender.com/api/ad-budget',
  update: 'https://billsby-mailgun.onrender.com/api/ad-budget/update',
  create: 'https://billsby-mailgun.onrender.com/api/ad-budget/create',
};

export const Invoice = {
  marketing: BASE_URL + '/api/marketing/statistics',
  all: BASE_URL + '/api/invoice/all',
  breakdown: BASE_URL + '/api/invoice/breakdown',
  getInvoiceFromDate: BASE_URL + '/api/invoice/date-range',
};

export const Evaluation = {
  getScored: BASE_URL + '/api/evaluation/scored-customers',
  getUnscored: BASE_URL + '/api/evaluation/unscored-customers',
  getUnscoredTotal: BASE_URL + '/api/evaluation/count-unscored-customers',
  updateCustomerInfo: BASE_URL + '/api/evaluation/update-customer-info',
  fetchCustomerInfo: BASE_URL + '/api/evaluation/fetch-customer-info',
  fetchLogs:
    BASE_URL + '/api/evaluation/fetch-update-logs?mode=evaluationCustomers',
};

export const InLife = {
  getCustomerList: BASE_URL + '/api/evaluation/fetch-in-life-customers',
  fetchLogs: BASE_URL + '/api/evaluation/fetch-update-logs?mode=inLife',
  fetchCustomerHistory:
    BASE_URL + '/api/evaluation/fetch-in-life-customers/info',
};
