import React, { useState } from "react"
import axios from 'axios'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitterSquare, faLinkedin, faFacebookSquare, faInstagramSquare, faYoutubeSquare  } from '@fortawesome/free-brands-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Connect from "../components/connect"
import classNames from 'classnames'
import BadgeContent from "../components/badge-component"
import MailTo from "../components/mail-to"

const badgeData = {
  section: "footer",
  link: true,
  FallBadges: [
    {
      img: require("../images/easiest-spring.svg"),
      altText: 'easiest-setup-spring',
    },
  ],
}

const Footer = (props) => {
  const [onSubmit, setOnSubmit] = useState({ message: "Keep me in the loop", isSubmitted: false });
  const [myEmail, hasEmail] = useState('')
  const [error, isError] = useState()
  const [disabled, isDisabled] = useState(true)

  const footerUrl = () => {
    return props.footerUrlAlt ? props.footerUrlAlt : "https://app.billsby.com/registration"
  }

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
    setOnSubmit({ message: "Keep me in the loop", isSubmitted: false });
  }

  const handleSubmit = event => {
    event.preventDefault();

    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const result = pattern.test(myEmail);

    if (result === true) {
      isError(false)
      isDisabled(true)
      setOnSubmit({ message: "Sending...", isSubmitted: false });

      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/69891226/subscribers`,
        headers: { 'content-type': 'application/json', 'X-MailerLite-ApiKey': 'f13b6f484a31a41bbdefdd972b1d6099' },
        data: {
          email: myEmail,
          type: 'active'
        }
      }).then(res => {
        setOnSubmit({ message: "Thanks! You're all set!", isSubmitted: true });
        hasEmail('');
      }).catch((error) => {
        setOnSubmit({ message: "Ops! Please try again.", isSubmitted: false });
      })
    } else (
      isError(true)
    )
  };

  return (
    <footer className={classNames({ "footer-layout-2": props.footerLayout2, "footer-layout-3": props.footerLayout3, "footer-layout-4": props.footerLayout4, "footer-layout-5": props.footerLayout5 })}>
      <div className="container">
        <div className="section-cta">
          <BadgeContent content={badgeData} />
          <h2 className="heading-cta">Try Billsby for yourself from as little as $35 a month</h2>
          {props.footerLayout3 && (
            <h2 className="heading-cta">Try Billsby for yourself from as little as $35 a month</h2>
          )}
          {props.footerLayout5 && (
            <h2 className="heading-cta">Try Billsby for yourself from as little as $35 a month</h2>
          )}
          {
            props.footerCTAContent ?
              props.footerCTAContent.linkIsExternal
                ?
                <a href={props.footerCTAContent.ctaBtnUrl} target="_blank" rel="noopener noreferrer" className={"btn-cta"}>Get started with your account today</a>
                :
                <a href={props.footerCTAContent.ctaBtnUrl} className={"btn-cta"}>Get started with your account today</a>
              :
              <a href={footerUrl()} className="btn-cta" target="_blank" rel="noopener noreferrer">Get started with your account today</a>
          }

        </div>
        <div className="section-main">
          <div className="row">
            <div className="col c-col">
              {
                props.footerLayout2
                  ?
                  ''
                  :
                  <>
                    <div className="row">
                      <div className="col link-wrapper">
                        <h5 className="heading-footer-1">Useful links</h5>
                        <ul>
                          <li>
                            <Link className="footer-link" to="/sitemap">Sitemap</Link>
                          </li>
                          <li>
                            <Link className="footer-link" to="/security">Security</Link>
                          </li>
                          <li>
                            <Link className="footer-link" to="/terms">Terms</Link>
                          </li>
                          <li>
                            <Link className="footer-link" to="/privacy">Privacy Policy</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col link-wrapper">
                        <h5 className="heading-footer-1">Company</h5>
                        <ul>
                          <li>
                            <Link className="footer-link" to="/company/about">About us</Link>
                          </li>
                          <li>
                            <Link className="footer-link" to="/company/careers">Careers</Link>
                          </li>
                          <li>
                            <Link className="footer-link" to="/company/press">Press</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col link-wrapper">
                        <h5 className="heading-footer-1">Support</h5>
                        <ul>
                          <li>
                            <a href="https://support.billsby.com/docs" className="footer-link" target='_blank' rel="noopener noreferrer">Documentation</a>
                          </li>
                          <li>
                            <a href="https://support.billsby.com/reference" className="footer-link" target='_blank' rel="noopener noreferrer">API specification</a>
                          </li>
                          <li>
                            <a href="https://status.billsby.com/" className="footer-link" target='_blank' rel="noopener noreferrer">Status</a>
                          </li>
                        </ul>
                      </div>
                      <div className="col link-wrapper">
                        <h5 className="heading-footer-1">Contact</h5>
                        <ul>
                          <li className="">
                          </li>
                          <li>
                            {MailTo({classes: 'footer-link'})}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
              }
              <Connect className="display-none display-lg-block" />
            </div>
            {
              props.footerLayout2
                ?
                ''
                :
                <div className="col c-col">
                  <h5 className="heading-footer-1">Subscribe</h5>
                  <p className="text-sm">The latest Billsby news, articles and resources sent straight to your inbox every week.</p>

                  <form>
                    <input className={`custom-input ${error ? 'error' : ''}`} type="email" name="emailAddress" placeholder="Enter your email address" value={myEmail} onChange={handleChange} />

                    {props.footerLayout4 ? <button type="submit" className={`btn-orange ${onSubmit.isSubmitted ? 'btn-submitted' : ''}`} onClick={handleSubmit} disabled={disabled}>
                      <FontAwesomeIcon icon={faCheckCircle} /> {onSubmit.message}
                    </button> :
                      <button type="submit" className={`btn-blue ${onSubmit.isSubmitted ? 'btn-submitted' : ''}`} onClick={handleSubmit} disabled={disabled}>
                        <FontAwesomeIcon icon={faCheckCircle} /> {onSubmit.message}
                      </button>
                    }
                  </form>

                  <p className="text-xs">We'll never share your details. See our <Link className="text-underline" to="/privacy">privacy policy</Link>.</p>
                </div>
            }

            <Connect className="display-lg-none" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer