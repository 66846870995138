import React, { useEffect, useState } from "react"
import axios from "axios"
// import moment from "moment"
import { HomeURLS } from "../helpers/Urls"

const SignedUpValue = () => {
  const [value, setValue] = useState({
    sumOfSignups: 0,
  })
  // eslint-disable-next-line
  const [statistics, setStatistics] = useState([])

  const fetchMarketingStats = () => {
    var endpoint = HomeURLS.thirtyDays,
      sumSignups = 0
    axios
      .get(endpoint, { headers: { Authorization: "Bearer null" } })
      .then(({ data }) => {
        setStatistics(data)
        sumSignups = data[0].signed_up

        setValue({
          sumOfSignups: sumSignups,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchMarketingStats()
  }, [])
  return <>{value.sumOfSignups}</>
}

export default SignedUpValue
