import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faLinkedin, faFacebookSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

const Connect = (props) => (
  <div className={`connect ${props.className}`}>
    <div className="row social-wrapper">
      <a href="https://www.twitter.com/billsbysaas" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitterSquare} />
      </a>
      <a href="https://www.linkedin.com/company/billsby" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://www.facebook.com/billsbysubscriptions/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookSquare} />
      </a>
      <a href="https://www.instagram.com/billsbysubscriptions/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagramSquare} />
      </a>
      <a href="https://www.youtube.com/channel/UCjqmQ0xkmDLcTHCH9Q1PN2g" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutubeSquare} />
      </a>
    </div>
    <p className="text-xs">&copy; Billsby (2021)</p>
    <p className="text-xs">A product of Airfi Networks</p>
  </div>
)

export default Connect